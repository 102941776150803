import React from 'react';
import { motion } from 'framer-motion';

const Section = ({ id, title, children }) => {
  return (
    <motion.section
      id={id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <h2>{title}</h2>
      {children}
    </motion.section>
  );
};

export default Section;