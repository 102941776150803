import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Map from './Map'; 

const MusicWorkshops = () => {
  const [showPracticalInfo, setShowPracticalInfo] = useState(true);
  const [showRegistration, setShowRegistration] = useState(true);

  const togglePracticalInfo = () => setShowPracticalInfo(!showPracticalInfo);
  const toggleRegistration = () => setShowRegistration(!showRegistration);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <h3>Présentation</h3>
      <p>
        Les orchestres Cadet et Junior de la FSM31 sont des orchestres d'harmonie regroupant des jeunes
        musiciens issus de tout le département de la Haute-Garonne dès 3 années de pratique instrumentale.
      </p>
      <p>
        Ils se réunissent chaque année à la Toussaint pour découvrir, apprendre et maîtriser tout un programme
        en une semaine de stage riche, tant sur le plan musical qu'humain, sous la direction artistique de Marina
        Marque-Bouaret et Laurent Itier. Ils sont accompagnés au quotidien par des musiciens diplômés ainsi
        qu'une équipe d'animateurs BAFA.
      </p>
      <p>
        Ce stage aboutit à un concert de clôture ainsi qu'une série de représentations sur la saison, permettant
        de faire rayonner ce projet annuel dans tout le département.
      </p>
      <p>
        C'est une occasion immanquable d'assister à un moment de musique convivial mettant en valeur la
        richesse du répertoire pour orchestre d'harmonie, mais aussi et surtout de partager la passion et le
        plaisir que ces jeunes musiciens ont à jouer ensemble.
      </p>

      <p><a href="../documents/presentation_stage_cadet.pdf" target="_blank" rel="noopener noreferrer">Le stage Cadet en détail</a></p>

      <h4 onClick={togglePracticalInfo} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        Infos Pratiques {showPracticalInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </h4>
      {showPracticalInfo && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h3>Dates et lieu</h3>
          <strong>Du samedi 26/10/2024 au vendredi 01/11/2024</strong> en pension complète.<br />
          Campus La Salle Saint Christophe - Dom. de Belliard, 32140 Masseube

          <Map />

          <h3>Tarifs</h3>
          <p><strong>Si issu d'une société fédérée :</strong><br />
            1 stagiaire : 325 € | 2 stagiaires* : 550 € | 3 stagiaires* : 730 €
          </p>
          <p><strong>Si non issu d'une société fédérée :</strong><br />
            1 stagiaire : 375 € | 2 stagiaires* : 630 € | 3 stagiaires* : 850 €
          </p>
          <p><i>* pour les enfants d'une même famille (frères et sœurs)</i></p>
          
          <h3>Niveau et âge</h3>
          <p>
            <strong>Cadet :</strong> à partir de 3 années de pratique / de 8 à 13 ans
          </p>
          <p>
            <strong>Junior :</strong> à partir du second cycle / de 12 à 25 ans
          </p>
          
          <h3>Informations complémentaires</h3>
          <p>
            Concert de fin de stage dans la région toulousaine le vendredi 1er novembre en fin de journée.<br />
            Bus prévu à cette occasion pour amener les stagiaires du lieu d'hébergement à la salle de concert.
          </p>
          <p>
            Les dates et lieux des autres concerts de la saison vous seront communiqués au plus tard lors du premier jour du stage.
          </p>
        </motion.div>
      )}

      <h4 onClick={toggleRegistration} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        Inscription {showRegistration ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </h4>
      {showRegistration && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <p>Pour vous inscrire, vous devez remplir le <a href="https://docs.google.com/forms/d/e/1FAIpQLSd5H8ZrwT2kyQ9cBUBnKw0fkDvrFKstkybgfQOJsoV22folYA/viewform?vc=0&c=0&w=1&flr=0"
                   target="_blank" rel="noopener noreferrer">formulaire d'inscription</a> ainsi que les documents suivants à joindre dans le formulaire :
            <ul>
              <li><a href="../documents/fiche_sanitaire.pdf" target="_blank" rel="noopener noreferrer">Fiche Sanitaire</a></li>
              <li><a href="../documents/autorisation_droit_image.pdf" target="_blank" rel="noopener noreferrer">Autorisation Droit à l'Image</a></li>
            </ul>
          </p>
        </motion.div>
      )}
    </motion.div>
  );
};

export default MusicWorkshops;
