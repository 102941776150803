import React from 'react';
import { Link } from 'react-scroll';
import logo_blanc from '../assets/logo_blanc.png';

const Header = () => {
  return (
    <header>
      <img src={logo_blanc} alt="FSM31 Logo" />
      <nav>
        <ul>
          <li><Link to="presentation" smooth={true} duration={500}>Présentation</Link></li>
          <li><Link to="workshops" smooth={true} duration={500}>Stages Musicaux</Link></li>
          <li><Link to="societies" smooth={true} duration={500}>Sociétés Fédérées</Link></li>
          <li><Link to="contacts" smooth={true} duration={500}>Contact</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;