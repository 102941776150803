import React from 'react';

const LinksContacts = () => {
  return (
    <div>
      <p><strong>Cadet :</strong> <a href="mailto:orchestrecadetfsm31@gmail.com">orchestrecadetfsm31@gmail.com</a></p>
      <p><strong>Junior :</strong> <a href="mailto:orchestrejuniorfsm31@gmail.com">orchestrejuniorfsm31@gmail.com</a></p>
    </div>
  );
};

export default LinksContacts;