import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Section from './components/Section';
import FederatedSocieties from './components/FederatedSocieties';
import MusicWorkshops from './components/MusicWorkshops';
import LinksContacts from './components/LinksContacts';
import './App.css';
import logoFSM31 from './assets/logo.png';

const App = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <div className="logo-container">
          <img src={logoFSM31} alt="FSM31 Logo" className="logo" />
        </div>
        <Section id="presentation" title="Présentation de la FSM31">
          <p>Ce site de la FSM31 est temporaire et va bientôt faire peau neuve. Retrouvez ici les informations les plus importantes en attendant une mise à jour du contenu.</p>
        </Section>
        <Section id="workshops" title="Stages Musicaux">
          <MusicWorkshops />
        </Section>
        <Section id="societies" title="Sociétés Fédérées">
          <FederatedSocieties />
        </Section>
        <Section id="contacts" title="Contact">
          <LinksContacts />
        </Section>
      </main>
      <Footer />
    </div>
  );
};

export default App;