import React from 'react';
// import MapComponent from './MapComponent';

const FederatedSocieties = () => {
  const societies = [
    { name: "Société 1", location: [43.604, 1.44305] }, // Toulouse
    { name: "Société 2", location: [43.6, 1.433] },    // Exemple de coordonnées
    { name: "Société 3", location: [43.61, 1.45] }     // Exemple de coordonnées
  ]; // Remplacez par les vraies données

  return (
    <div>
      {/* <h3>La fédération compte {societies.length} sociétés affiliées</h3> */}
      <ul>
        {/* {societies.map(society => (
          <li key={society.name}>{society.name}</li>
        ))} */}
        <li>Vous trouverez ici la <a href="../documents/societes_fsm31.pdf" target="_blank" rel="noopener noreferrer">liste des sociétés affiliées à la FSM31</a>.</li>
      </ul>
      {/* <h3>Carte des Sociétés</h3> */}
      {/* <MapComponent societies={societies} /> */}
    </div>
  );
};

export default FederatedSocieties;
